import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import Footer from "../components/footer";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { Gradient, GradientWapper } from "../components/gradients";
import { StaticImage } from "gatsby-plugin-image";
import Decor from "../components/decor";


const Page = styled.div``;

const HeaderText = styled.div`
  width: auto;
  display: inline;
  text-align: left;
  padding: 6rem 0 0;
  max-width: 30rem;
  h1 {
    display: inline-block;
    margin-bottom: 1.8rem;
  }
  p {
    margin: 0 0 1.4rem;
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 5rem 0 0;
    text-align: center;
  }
`;

const MediaKitDownloads = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  > div {
    width: 46%;
    .image-container {
      padding-bottom: 2.2rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgb(248 248 248 / 80%);
    }
    .asset-name a {
      display: flex;
      align-items: center;
      p {
        margin: 0 0 0 0.5rem;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    display: block;
    text-align: center;
    > div {
      margin: 0 auto 4rem;
      width: 92%;
    }
  }
`;

const MediaKitPage = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  const DecorSettings = {
    desktop: [
      {
        colour: "white",
        size: 15,
        left: 325,
        top: -90,
      },
      {
        colour: "white",
        size: 11,
        left: 220,
        top: 0,
      },
      {
        colour: "purple",
        size: 22,
        left: -34,
        top: -120,
      },
    ],
    mobile: [
      {
        colour: "purple",
        size: 15,
        left: -150,
        top: -110,
      },
      {
        colour: "white",
        size: 15,
        left: 134,
        top: -84,
      },
      {
        colour: "white",
        size: 7.5,
        left: 68,
        top: 36,
      },
    ],
  };

  return (
    <Page>
      <Layout title={"Media Kit"} description={"Download the Bifrost Wallet media kit in SVG and PNG."}>
        <GradientWapper>
          <Gradient position="topLeft" color="purple" />
          <Block noBottomPadding={true} justifyContent={"left"}>
            <HeaderText>
              <Decor
                settings={isMobile ? DecorSettings.mobile : DecorSettings.desktop}
              >
                <h1>Download the Bifrost Wallet media kit</h1>
              </Decor>
              <p>Download our brand assets in SVG and PNG format.</p>
            </HeaderText>
          </Block>
          <Gradient position="bottomRight" color="blue" />
        </GradientWapper>
        <Block>
          <MediaKitDownloads>
            <div>
              <div className="image-container">
                <StaticImage
                  src="../images/LogoMediaKit.png"
                  quality={100}
                  alt="Bifrost Wallet Logo"
                  height={60}
                  placeholder="none"
                />
              </div>
              <div className="asset-name">
                <a href="/bifrost-wallet-media-kit.zip" title="Download Logo">
                  <StaticImage
                    src="../images/Download.png"
                    quality={100}
                    alt="Download Icon"
                    height={22}
                    placeholder="none"
                  />{" "}
                  <p>Bifrost Wallet logo - ZIP file</p>
                </a>
              </div>
            </div>
          </MediaKitDownloads>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export default MediaKitPage;
